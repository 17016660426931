<template>
  <div>
    <b-card  v-for="Specialty in doctorList"  :key="Specialty.ID">
      <h3 class="text-center mb-8">{{Specialty.SpecialtyName}}</h3>
      <b-row>
        <b-col  v-for="doctor in Specialty.doctors"  :key="doctor.ID">
          <div class=" mr-8" >
                       <imageLazy
                      :imageStyle="{ 'border-radius': '50px','width':'150px', 'height':'150px' }"
                      :imageClass="'rounded-full'"
                      :src="baseURL + doctor.ProfileImagePath"
                      placeHolderType="doctor"
                    />
          </div>
        </b-col>
        <b-col v-for="doctor in Specialty.doctors"  :key="doctor.ID">
          <h6 @click="DoctorProfile(doctor.ID)" style="cursor:pointer">{{ $t("Dr") }}. {{ doctor.Name }}</h6>
           <h6 class="mt-1">{{ $t("Title") }} : {{ doctor.Title }}</h6>
            <h6 class="mt-1">{{ $t("Address") }} : {{ doctor.Address }}</h6>
            <h6 class="mt-1" v-if="doctor.Bio">{{ $t("Biography") }} : {{ doctor.Bio.substring(0,100) }} ... </h6>
        </b-col>
      </b-row>
    </b-card>
  </div>
  
</template>
<script>
import HospitalModule from "@/store/hospital/moduleHospital.js";
import { domain } from "@/gloabelConstant.js";
import imageLazy from "@/components/image_lazy.vue";
import {
    BAvatar, BButton, BCard, BCardHeader, BCardTitle,
    BCol, BForm, BFormCheckbox, BFormGroup, BFormInput,
    BFormRadioGroup, BMedia, BRow, BTable, BImg
} from 'bootstrap-vue'

export default {
  components: {
    BAvatar, BButton, BCard, BCardHeader, BCardTitle,
    BCol, BForm, BFormCheckbox, BFormGroup, BFormInput,
    BFormRadioGroup, BMedia, BRow, BTable, BImg,
imageLazy
  },
  data() {
    return {
 doctorList:[],

      baseURL: domain,

    };
  },
  props: {
        hospital: {
            type: Object,
            required: true,
        },
    },
  methods: {

  },
  computed: {

  },
  created() {
  if (!HospitalModule.isRegistered) {
      this.$store.registerModule("HospitalList", HospitalModule);
      HospitalModule.isRegistered = true;
    }
     var id = this.$route.params.ID;
    this.$store.dispatch("HospitalList/SearchHospitalSpecialtys", {"HospitalID":id}).then(res=>{
       this.doctorList = res.data.Data;

    });
  },
};
</script>
