<template>
    <b-row>
        <b-col class="ml-5 mt-2" v-for="item in hospital.HospitalAttachments" :key="item.ID">
            <div>
                <b-col>
                    <imageLazy :imageStyle="{ 'border-radius': '10px', 'height': '200px', 'width': '220px' }"
                        :imageClass="'w-full'" :src="baseURL + item.AttachPath" placeHolderType="hospital" />
                </b-col>
            </div>
        </b-col>
    </b-row>
</template>

<script>
import { domain, mainSetting } from "@/gloabelConstant.js"
import { BAvatar, BButton, BCard, BCardHeader, BCardTitle, BCol, BForm, BFormCheckbox, BFormGroup, BFormInput, BFormRadioGroup, BMedia, BRow, BTable } from 'bootstrap-vue'
import VuePhoneNumberInput from 'vue-phone-number-input'
import vSelect from 'vue-select';
import imageLazy from "@/components/image_lazy.vue";
import StarRating from "vue-star-rating";
export default {
    components: {
        VuePhoneNumberInput,
        BFormRadioGroup,
        BButton,
        BMedia,
        BAvatar,
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BForm,
        BTable,
        BCard,
        BCardHeader,
        BCardTitle,
        BFormCheckbox,
        vSelect,
        imageLazy,
        StarRating,
    },
    data() {
        return {
            baseURL: domain,
            UploadRequestHeader: mainSetting.UploadRequestHeader,
            uploadUrl: domain + "api/Common/UploadFile",
            radios1: "luis",
            visible: false,
        };
    },
    props: {
        hospital: {
            type: Object,
            required: true,
        },
    },

    watch: {

    },
    methods: {
      
    },
    computed: {

    },
    created() {


    },
}

</script>

<style>

</style>

