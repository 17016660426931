<template>
      <b-tabs  pills>
      <!-- Tab: Account -->
      <b-tab active>
        <template #title>
          <feather-icon icon="UserIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">{{$t('AboutHospital')}}</span>
        </template >
           <HospitalMAinInfo  :hospital="hospital" class="mt-2 pt-75" /> 
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="LockIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline"> {{$t('HospitalImages')}}</span>
        </template>
        <HospitalImages :hospital="hospital" class="mt-2 pt-75"  />
      </b-tab>

      <b-tab>
        <template #title>
          <feather-icon icon="UserIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline"> {{$t('HospitalDoctorSpecialities')}}</span>
        </template>
         <HospitalSpecialities :hospital="hospital"  class="mt-2 pt-75" />
      </b-tab>

    </b-tabs>
</template>

<script>

import { domain, mainSetting } from "@/gloabelConstant.js";
import { BCardText, BTableLite,BBadge, BButton, BCard, BCardBody, 
  BCol, BFormSpinbutton, BImg, BLink, BRow, BTab, BTabs } from 'bootstrap-vue';
import moduleHospital from "@/store/hospital/moduleHospital.js";
import moduleCity from "@/store/settings/city/moduleCity.js";
import moduleCountry from "@/store/settings/country/moduleCountry.js";
import moduleFacility from "@/store/settings/facility/moduleFacility.js";
import moduleHospitalGroup from "@/store/settings/HospitalGroup/moduleHospitalGroup.js";
import moduleRegion from "@/store/settings/region/moduleRegion.js";
import HospitalMAinInfo from '@/views/pages/Profiles/HospitalMAinInfo';
import HospitalImages from "./HospitalImages.vue";
import HospitalSpecialities from "./HospitalSpecialities.vue";

export default {
 data() {
    return {
      baseURL: domain,
       Model:[],
       activeTab: 0,
    EnglishLan:false,
     OldCount:0,
     CommingCount:0,
     hospital: {
        HospitalAttachments: [],
        HospitalSpecialties: [],
        HospitalFacilities: [],
        
      },

    };
  },
  components:{
 
  BCardText, BTableLite,BBadge,
  BButton, BCard, BCardBody, BCol,
  BFormSpinbutton, BImg, BLink, 
  BRow, BTab, BTabs,
  mainSetting,
  HospitalMAinInfo,
  HospitalImages,
  HospitalSpecialities,

  },
  
 methods:{
  show() {
      this.visible = true
    },
    handleHide() {
      this.visible = false
    },
    ViewSlider()
    {
         this.ImageSlider=true;
    },
    getRegionByCountryId(CountryId) {
      this.$store
        .dispatch("RegionList/GetAllRegionsByCountryID", CountryId)
        .then(() => {
          if (this.hospital.RegionID > 0) {
            this.getCityByRegionId(this.hospital.RegionID);
          }
        });
    },
    getCityByRegionId(RegionId) {
      this.$store.dispatch("CityList/GetAllCitiesByRegionID", RegionId);
    },
       back() {
      this.$router.go(-1);
    }

 },
 computed: {
    swiper() {
        return this.$refs.mySwiper.$swiper
      },
    cities() {
      return this.$store.state.CityList.cities;
    },
    countries() {
      return this.$store.state.CountryList.countries;
    },
    regions() {
      return this.$store.state.RegionList.regions;
    },
    hospitalGroups() {
      return this.$store.state.HospitalGroupList.HospitalGroups;
    },
    facilities() {
      debugger;
      return this.$store.state.FacilityList.facilities;
    },
    filterHospitalAttachments() {
      return this.hospital.HospitalAttachments.filter((obj) => obj.ID > 0);
    },
  },
  created() {
   
    if (!moduleRegion.isRegistered) {
      this.$store.registerModule("RegionList", moduleRegion);
      moduleRegion.isRegistered = true;
    }
    if (!moduleCity.isRegistered) {
      this.$store.registerModule("CityList", moduleCity);
      moduleCity.isRegistered = true;
    }
    if (!moduleCountry.isRegistered) {
      this.$store.registerModule("CountryList", moduleCountry);
      moduleCountry.isRegistered = true;
    }

    if (!moduleHospitalGroup.isRegistered) {
      this.$store.registerModule("HospitalGroupList", moduleHospitalGroup);
      moduleHospitalGroup.isRegistered = true;
    }
    if (!moduleFacility.isRegistered) {
      this.$store.registerModule("FacilityList", moduleFacility);
      moduleFacility.isRegistered = true;
    }
    if (!moduleHospital.isRegistered) {
      this.$store.registerModule("HospitalList", moduleHospital);
      moduleHospital.isRegistered = true;
    }

    this.$store.dispatch("FacilityList/GetAllFacilities");
    this.$store.dispatch("HospitalGroupList/GetAllHospitalGroups");
    this.$store.dispatch("CountryList/GetAllCountries");
    this.$store.dispatch("RegionList/GetAllRegions");
    this.$store.dispatch("CityList/GetAllCities");
    var id = this.$route.params.ID;

    if (id) {
      this.$store.dispatch("HospitalList/GetHospital", id).then((res) => {
        this.hospital = res.data.Data;
           var vm=this;
        this.slides=this.hospital.HospitalAttachments.map(ob=>vm.baseURL + ob.AttachPath)
        if (this.hospital.CountryID > 0) {
          this.getRegionByCountryId(this.hospital.CountryID);
        }
        if (this.hospital.HospitalFacilities != null) {
          this.hospital.HospitalFacilities.forEach((element) => {
            var index = this.facilities.findIndex(
              (x) => x.ID == element.FacilityID
            );
            debugger;
            if (index >= 0) this.facilities[index].IsChecked = true;
          });
        }
      });
    }
  },
  mounted(){
     this.swiper.slideTo(3, 1000, false)
  }
};
</script>
<style >
.vs-tabs--li {
    display: block;
    width: 16rem;
    position: relative;

}
.vuesax-app-is-ltr .vs-tabs--btn-icon+span {
    padding-left: 9px;
    font-size:larger;
  letter-spacing: 1px;
  font-weight: bold;

}
</style>
