<template>
    <div>
        <b-row>
            <b-col cols="12" md="4">
                <b-media class="mb-2">
                    <template #aside>
                        <imageLazy :imageStyle="{ 'border-radius': '10px', 'height': '200px', 'width': '220px' }"
                            :imageClass="'w-full'" :src="baseURL + hospital.ImagePath" placeHolderType="hospital" />
                    </template>

                </b-media>
                <h4 class="mb-2 text-primary">
                    {{ hospital.Name }}
                </h4>
                <b-row>
                    <b-col>
                        <h5 class="lg:w-1/2" style="color: #696688">
                            {{ $t("Rate") }} :
                        </h5>
                    </b-col>
                    <b-col>

                        <star-rating class="" :rtl="$vs.rtl" :star-size="25" v-model="hospital.Rate" :border-width="2"
                            :disable="true" border-color="#d8d8d8" :rounded-corners="false" :star-points="[
    23, 2, 14, 17, 0, 19, 10, 34, 7, 50, 23, 43, 38, 50, 36, 34,
    46, 19, 31, 17,
]" :read-only="true" :show-rating="false"></star-rating>
                    </b-col>

                </b-row>

                <b-row class="mt-1">
                    <b-col>
                        <h5 class="lg:w-1/2 mb-5" style="color: #696688">
                            {{ $t("RecommendationLevel") }} :
                        </h5>
                    </b-col>
                    <b-col>
                        <star-rating class="mb-5" :rtl="$vs.rtl" :star-size="25" v-model="hospital.RecommendedLevel"
                            :border-width="2" :disable="true" border-color="#d8d8d8" :rounded-corners="false"
                            :star-points="[
    23, 2, 14, 17, 0, 19, 10, 34, 7, 50, 23, 43, 38, 50, 36, 34,
    46, 19, 31, 17,
]" :read-only="true" :show-rating="false"></star-rating>
                    </b-col>



                </b-row>
            </b-col>
            <b-col cols="12" md="8">

                <div>
                    <h3 class="headText">
                        <p v-html="hospital.HospitalInfo.substring(0, numberOfWords)"> </p> <u style="color:#1DC5F2"
                            v-if="numberOfWords == 300" @click="numberOfWords = 20000">{{ $t('ShowMore') }}</u>
                    </h3>
                </div>
            </b-col>
        </b-row>

        <b-form>
            <b-row>
                <b-col cols="12" md="6">
                    <h2 class="mt-2">
                        {{ $t("MainInfo") }}
                    </h2>
                    <b-row>
                        <b-col cols="12" md="5">
                            <h4 class="mt-1">
                                {{ $t("Country") }}:
                                <div div class="justify-center">
                                    <v-select :dir="$vs.rtl ? 'rtl' : 'ltr'" name="Country" class=" mt-1" disabled
                                        label="Name" :options="countries" :reduce="(ID) => ID.ID"
                                        v-model="hospital.CountryID" />
                                </div>
                            </h4>

                            <h4 class="mt-1">
                                {{ $t("Region") }}:
                                <div div class="w-full justify-center">
                                    <v-select :dir="$vs.rtl ? 'rtl' : 'ltr'" name="Region" class="w-full mt-1" disabled
                                        :options="regions" label="Name" :reduce="(ID) => ID.ID"
                                        v-model="hospital.RegionID" />
                                </div>
                            </h4>

                            <h4 class="mt-1">
                                {{ $t("City") }}:
                                <div div class="w-full justify-center">
                                    <v-select :dir="$vs.rtl ? 'rtl' : 'ltr'" name="city" class="w-full mt-1" disabled
                                        label="Name" :options="cities" :reduce="(ID) => ID.ID"
                                        v-model="hospital.CityID" />
                                </div>
                            </h4>

                        </b-col>

                    </b-row>
                </b-col>

                <b-col cols="12" md="6">
                    <h1 class="justify-center mt-2">
                        {{ $t("Accommodation") }}
                    </h1>

                    <h2 class="justify-center mt-1  text-primary">
                        {{ $t("HospitalRooms") }}
                    </h2>
                    <b-row>
                        <div class="ml-2 mt-4 ">
                            <h4 class="headText">
                                {{ hospital.RoomDescription }}
                            </h4>
                        </div>
                    </b-row>

                    <h2 class="justify-center mt-0 text-primary">
                        {{ $t("Facilities") }}
                    </h2>
                    <b-row vs-justify="center">
                        <b-col v-for="item in facilities" :key="item.ID" class="justify-left mt-1" cols="12" md="6">
                            <b-row>
                                <b-col cols="12" md="6">
                                    <span class="SpanTextClass text-primary">{{ item.Name }}</span>
                                </b-col>
                                <b-col>
                                    <b-img style="width: 50px; height: 20px" alt="" :src="baseURL + item.ImagePath" />
                                </b-col>

                            </b-row>

                        </b-col>
                    </b-row>

                    <div class="mt-2 ">
                        <h2 class="text-primary">
                            {{ $t("Accompanyingperson") }}
                        </h2>
                    </div>

                    <h4 class="mt-0">
                        {{ $t("Forinpatientsanaccompanyingpersonisallowedtostaywiththepatientintheroom") }}
                    </h4>
                </b-col>
            </b-row>

        </b-form>

    </div>
</template>
  
<script>
import axios from "@/axios.js"
import { domain, mainSetting } from "@/gloabelConstant.js"
import moduleLanguage from "@/store/settings/language/moduleLanguage.js"
import {
    BAvatar, BButton, BCard, BCardHeader, BCardTitle,
    BCol, BForm, BFormCheckbox, BFormGroup, BFormInput,
    BFormRadioGroup, BMedia, BRow, BTable, BImg
} from 'bootstrap-vue'
import VuePhoneNumberInput from 'vue-phone-number-input'
import vSelect from 'vue-select';
import imageLazy from "@/components/image_lazy.vue";
import StarRating from "vue-star-rating";



export default {
    components: {
        VuePhoneNumberInput,
        BFormRadioGroup,
        BButton,
        BMedia,
        BAvatar,
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BForm,
        BTable,
        BCard,
        BCardHeader,
        BCardTitle,
        BFormCheckbox,
        BImg,
        vSelect,
        imageLazy,
        StarRating,
    },
    data() {
        return {
            numberOfWords:30000,
            baseURL: domain,
            UploadRequestHeader: mainSetting.UploadRequestHeader,
            uploadUrl: domain + "api/Common/UploadFile",
            options: [],
            radios1: "luis",
        };
    },
    props: {
        hospital: {
            type: Object,
            required: true,
        },
    },

    watch: {
        UserUpdetedData() {
            this.defaultPhoneData.countryCode = this.countries.find((x) => x.ID === this.UserUpdetedData.CountryID).IsoCode
        }
    },
    methods: {

    },
    computed: {
        facilities() {
            debugger;
            return this.$store.state.FacilityList.facilities;
        },
        languages() {
            return this.$store.state.LanguageList.languages;
        },

        countries() {
            return this.$store.state.CountryList.countries;
        },
        swiper() {
        return this.$refs.mySwiper.$swiper
      },
    cities() {
      return this.$store.state.CityList.cities;
    },
    regions() {
      return this.$store.state.RegionList.regions;
    },
    hospitalGroups() {
      return this.$store.state.HospitalGroupList.HospitalGroups;
    },
    },
    created() {
        if (!moduleLanguage.isRegistered) {
            this.$store.registerModule("LanguageList", moduleLanguage);
            moduleLanguage.isRegistered = true;
        }
        this.$store.dispatch("LanguageList/GetAllLanguages");
        this.$store.dispatch("CountryList/GetAllCountries");

        this.onSelectCountry();

    },
}
</script>
  
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
<style>
.SpanTextClass {
    font-size: 1.1rem !important;
}
</style>
  