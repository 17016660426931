/*=========================================================================================
  File Name: moduleQuestionBankActions.js
  Description: QuestionBank Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from "@/axios.js";

export default {
  AddHospitalGroup({ commit }, item) {
    debugger;
    return new Promise((resolve, reject) => {
      axios
        .post("/API/HospitalGroup/AddHospitalGroup", item)
        .then(response => {
          commit(
            "ADD_ITEM",
            Object.assign(item, { ID: response.data.Data.ID })
          );
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  GetAllHospitalGroups({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/API/HospitalGroup/GetAllHospitalGroups")
        .then(response => {
          debugger;
          commit("SET_HospitalGroups", response.data.Data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  UpdateHospitalGroup({ commit }, item) {
    return new Promise((resolve, reject) => {
      debugger;
      axios
        .post("/API/HospitalGroup/UpdateHospitalGroup", item)
        .then(response => {
          if (response.status == 200) {
            commit("UPDATE_HospitalGroup", item);
            resolve(response);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  GetHospitalGroup(context, itemid) {
    return new Promise((resolve, reject) => {
      axios
        .get("/API/HospitalGroup/GetHospitalGroup?ID=" + itemid)
        .then(response => {
          resolve(response);

          // commit('UPDATE_QuestionBank', response.data.data)
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  DeleteHospitalGroup({ commit }, item) {
    return new Promise((resolve, reject) => {
      debugger;
      axios
        .delete("/API/HospitalGroup/DeleteHospitalGroup?ID=" + item.ID)
        .then(response => {
          commit("REMOVE_ITEM", item.ID);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};
